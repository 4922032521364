import * as AWS from 'aws-sdk'
// STARTS HERE

const configuration = {
    region: process.env.REACT_APP_DDB_REGION,
    secretAccessKey: process.env.REACT_APP_DDB_ACCESS_KEY,
    accessKeyId: process.env.REACT_APP_DDB_ACCESS_ID,
}
AWS.config.update(configuration)
// ENDS HERE

const docClient = new AWS.DynamoDB.DocumentClient()

export const fetchData = (tableName) => {
    var params = {
        TableName: tableName,
    }
    docClient.scan(params, function (err, data) {
        if (!err) {
            return data;
        } else {
            console.log(err);
        }
    })
    return "";
}

export const getItem = async (tableName, _file_id, _projectedExpression) => {
    var params = {
        TableName: tableName,
        Key: {
            file_id: _file_id
        },
    }
    if(_projectedExpression){
        params = {
            TableName: tableName,
            Key: {
                file_id: _file_id
            },
            ProjectionExpression: _projectedExpression
        }
    }

    result = "++++++++++";
    let result = await docClient.get(params).promise();
    // console.log(result);
    return result;
}

export const putData = (tableName , data) => {
    var params = {
        TableName: tableName,
        Item: data
    }
    
    docClient.put(params, function (err, data) {
        if (err) {
            console.log('Error', err)
        } else {
        }
    })
}

import { Heading } from '@ensdomains/thorin'
import ConnectButtonWrapper from '../components/connect-button'

export default function Header({ position }) {
  console.log(window.innerWidth);
  return (
    <header className={['header', [position && `header--${position}`]].join(' ')}>
      {/* { window.innerWidth < 600 ? <img src="/ens-doc-small.png" style={{height:"70px"}}></img> : */}
        <img src="/ens-doc.png" style={{height:"100px"}}></img>
      {/* } */}
      <ConnectButtonWrapper />
    </header>
  )
}